[hidden] {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}

:root {

  //var(--ion-color-dark);: var(--ion-color-dark);
  //var(--ion-color-medium);: var(--ion-color-medium);
  //var(--ion-color-medium);-lightest: var(--ion-color-light);

  ion-button.md {
    text-transform: unset;
  }
  
  
  }

  ion-toggle {
    zoom: 0.9;
  }
    
  ion-content.add-background{
      --background:
      no-repeat center center fixed
      linear-gradient(
          rgba(114, 143, 153, 0.47),
          rgba(119, 153, 155, 0.932)
      ),
      url("../assets/aba-connect-background.jpg");
  
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
  }
  
  
  .profile-match-fields {
      border: solid 1px;
      border-color: var(--ion-color-primary);
      padding: 2px;
      width: 25%
  }
  
  @media screen and (max-width: 600px) {
  
      .profile-match-fields {
          border: solid 1px;
          border-color: var(--ion-color-primary);
          padding: 2px;
          width: 50%
      }
  }
  
  
  
  @media screen and (min-width: 1080px) {
      .popover-setting {
          margin-left:calc(-.6*(100vw - 1080px));
  
      }
  }
  
  
